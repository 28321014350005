<template>
  <v-app>
    <router-view />
    <v-snackbar
      v-model="$store.state.main.snackbar.fail"
      color="error"
      :timeout="timeout"
      top
    >
      {{ $store.state.main.snackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="$store.commit('main/setSnackbarFail')"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="$store.state.main.snackbar.success"
      color="success"
      :timeout="timeout"
      top
    >
      {{ $store.state.main.snackbar.message }}
      <template v-slot:actions="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="$store.commit('main/setSnackbarSuccess')"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- <v-overlay :value="$store.state.main.loading">
      <lottie-animation path="src/assets/processing.json" />
    </v-overlay> -->
  </v-app>
</template>

<script>
//import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "App",
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Ipaymu Payment Instant Checkout',
      // all titles will be injected into this template
      titleTemplate: '%s | Ipaymu Payment Instant Checkout'
  },
  data: () => ({
    timeout: 3000,
  }),
  mounted() {
    //document.title = "IPaymu Payment Instant Checkout";
  },
  // components: {
  //   LottieAnimation,
  //   // Sidebar,
  //   // // Navbar,
  //   // // Footbar,
  // },
};
</script>
