import Vue from "vue";
//import VueRouter from "vue-router";
import VueRouter  from 'vue-router'
//import createWebHashHistory  from 'vue-router'
import MainLayout from '../views/Main.vue'
import Utama from '../views/pages/mainUtama.vue'
import NotFound from '../views/pages/notfound.vue'
import ThankYouPage from '../views/pages/thankyou.vue'
import CancelPage from '../views/pages/cancel.vue'

if (!window.VueRouter) Vue.use(VueRouter)

const routes = [
    //Login
    {
        path: '/',
        name: 'Main',
        component: MainLayout,
        children: [
            {
                path: '/',
                name: 'iPaymu - Payment Instant Checkout',
                component: Utama
            },
            {
                path: '/notfound',
                name: 'iPaymu - Qr Instant 404',
                component: NotFound
            },
            {
                path: '/thankyou',
                name: 'iPaymu - Qr Instant - Thank You page',
                component: ThankYouPage
            },
            {
                path: '/cancel',
                name: 'iPaymu - Qr Instant - Cancel page',
                component: CancelPage
            }
        ]
    }
]

const router = new VueRouter({
    //mode: createWebHashHistory(process.env.API_URL_LOCAL),
    //mode: "hash",
    mode: "hash",
    routes
});

export default router