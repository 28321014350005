<template>

<div>
    
    <v-layout>

        <v-row>

            <v-col sm="12" md="12" 
                align="center"
                justify="center" style="background:#142699;">

                <v-col sm="11" md="11" cols="11" style="border-radius:20px; background:#FFFFFF; margin-top:20px; height:80vh; width:100%; padding:0 0; box-sizing:border-box; overflow:auto">
                
                    <div>

                        <v-row 
                            class="d-flex align-center justify-center"
                        >
                            <v-col sm="12" md="12" cols="12" style="margin-top:12vh;">
                                <img src="../../../assets/imageip.png" />
                            </v-col>

                            <v-col sm="12" md="12" cols="12"> 
                                <img src="../../../assets/404-error.png" style="margin-top:20px;" />
                            </v-col>

                            <v-col sm="12" md="12" cols="12">

                                <div style="color:#142699; font-size:24px; margin-top:20px; font-weight:bold;">
                                    Page Not Found
                                </div>

                                <div style="color:#868E96; font-size:14px; margin-top:10px;">
                                    Please check your link, <br /> make sure your link is correct.
                                </div>

                            </v-col>

                        </v-row>

                    </div>

                </v-col>

            </v-col>

        </v-row>

    </v-layout>

</div>

</template>

<script>
//import CustomCard from "../../../../components/CustomCard.vue";
//import downloadexcel from "vue-json-excel";


export default {
  data() {
    return {
      tes:"tes"
    }
  },
  //components: { CustomCard , downloadexcel },
  //components: { CustomCard },
};
</script>

<style>
</style>