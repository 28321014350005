import { apiFetchDetailMember , postCheckoutForm } from "../../api/member"


const namespaced = true

const defaultState = () => {
    return {
        member: {
            list: [],
            detail: {},
            listDetail:[],
            listAll:[],
            listLogs:[],
            listMemberVeMoney:[],
            listMemberThirdParty:[],
            listMemberTrans:[],
            listPaymentInter:[],
            listPaymentLocalVa:[],
            listPaymentLocalQris:[],
            listPaymentLocalChannel:[],
            resError:[],
            paymentRes:[],
            success:"",
            total:0,
            totalLogs:0,
            totaluserreg:0,
            totaluseractive:0,
            is_back:0,
            paging_page:1,
            hasPaymentInter:0,
            titlestab:"",
            indexRank:1,
            pageEmoney: "list",
            page: "list",
            pageReq: "list",
            pageIdCash:"list",
            pageLogReq: "list",
            pageThirdParty: "list",
        },
        settlement: {
            list: [],
            listAll:[],
            detail: {}
        },
        exports: {
           filename:"",
        },
    }
}

const state = defaultState()

const mutations = {
    setMemberEmoney: (state, payload) => {
        state.member.listMemberVeMoney = payload
    },
    setResError: (state, payload) => {
        state.member.resError = payload
    },
    setlistPaymentInter: (state, payload) => {
        state.member.listPaymentInter = payload
    },
    setPaymentRes: (state, payload) => {
        state.member.paymentRes = payload
    },
    setlistPaymentLocalVa: (state, payload) => {
        state.member.listPaymentLocalVa = payload
    },
    setlistPaymentLocalQris:(state, payload) => {
        state.member.listPaymentLocalQris = payload
    },
    setlistPaymentLocalChannel:(state, payload) => {
        state.member.listPaymentLocalChannel = payload
    },
    setDetailEmoney: (state, payload) => {
        state.member.DetailEmoney = payload
    },
    setMemberPage: (state, payload) => {
        state.member.page = payload
    },
    setPageThirdParty: (state, payload) => {
        state.member.page = payload
    },
    setlistMemberThirdParty: (state, payload) => {
        state.member.listMemberThirdParty = payload
    },
    setFormSwitch: (state, payload) => {
        state.member.formStates = payload
    },
    setMemberTransListAll: (state, payload) => {
        state.member.listMemberTrans = payload
    },
    setMemberListLogs: (state, payload) => {
        state.member.listLogs = payload
    },
    setSuccess: (state, payload) => {
        state.member.success = payload
    },
    setMemberDetail: (state, payload) => {
        state.member.detail = payload
    },
    setMemberListDetail: (state, payload) => {
        state.member.listDetail = payload
    }
}

const actions = {
    fetchDetailMember({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchDetailMember(payload)
                .then((res) => {
                    //console.log("load_pay" , payload.qrcode);
                    

                    if (res.status == 200) {
                        let payment_types = res.data.payment_types;

                        const view = "international"

                        const payment_methods = payment_types.find(obj => {
                            return obj.name === view;
                        });

                        const local = "local"

                        const payment_local = payment_types.find(obj => {
                            return obj.name === local;
                        });

                        const channels = "va";

                        const channel_va = payment_local.payment_methods.find(obj => {
                            return obj.code === channels;
                        });

                        // const channelsqris = "qris";
                        // const channel_qris = payment_local.payment_methods.find(obj => {
                        //     return obj.code === channelsqris;
                        // });
                        //console.log("load_va" , payment_methods);
                        const interchannels = payment_methods != null ? payment_methods.payment_methods : []
                        if(payment_methods != null){
                            state.member.hasPaymentInter = 1;
                        }

                        //console.log("interpay" , state.member.hasPaymentInter);

                        commit('setMemberListDetail', res.data);
                        commit('setlistPaymentInter' , interchannels);
                        commit('setlistPaymentLocalVa' , channel_va.payment_channels);
                        commit('setlistPaymentLocalChannel' , payment_local.payment_methods);
                        
                        //commit('setlistPaymentLocalQris' , channel_qris);
                        //console.log("payments" , res.data.payment_types[0].payment_methods);
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    else{
                        commit('setResError' , "errors");
                        reject(res)
                    }
                    
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postCheckout({ commit }, payload) {
        return new Promise((resolve, reject) => {
            postCheckoutForm(payload)
                .then((res) => {
                if (res.status == 200) {
                commit("setSuccess" , "Success")
                commit("setPaymentRes" , res.data);

                resolve(res)
                }
                reject(res)
            }).catch(res => reject(res))
        })
    }
}

export default {
    namespaced, state, mutations, actions
}