import request_util from '../util/free_request'

//import request_util_download from '../util/requestdownload'
//tes

export function apiFetchDetailMember(data) {
    return request_util({
        url: '/services/payment/v1/qr-instant/'+data.qrcode,
        method: 'GET',
        data: data
    })
}

export function postCheckoutForm(data) {
    return request_util({
        url: '/services/payment/v1/qr-instant/',
        method: 'POST',
        data: data
    })
}



//export default function apiFetchListMember()