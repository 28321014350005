<template>

<div>
    
    <v-layout>

        <v-row>

            <v-col sm="12" md="12" 
                align="center"
                justify="center" style="background:#142699;">

                <v-col sm="11" md="11" cols="11" style="border-radius:20px; background:#FFFFFF; margin-top:20px; height:80vh; width:100%; padding:0 0; box-sizing:border-box; overflow:auto">
                
                    <div>

                        <v-row 
                            class="d-flex align-center justify-center"
                        >
                            <v-col sm="12" md="12" cols="12" style="margin-top:5vh;">
                                <img src="../../../assets/imageip.png" />
                            </v-col>

                            <v-col sm="12" md="12" cols="12"> 
                                <img src="../../../assets/paymentpending.png" style="margin-top:20px; width:80%;" />
                            </v-col>

                            <v-col sm="12" md="12" cols="12">

                                <div style="color:#142699; font-size:24px; margin-top:20px; font-weight:bold;">
                                    Payment Cancel
                                </div>

                                <div style="color:#868E96; font-size:14px; margin-top:10px;">
                                    Sorry, your payment is failed. <br /> Please try again lates, or contact our support
                                </div>

                                <v-col sm="4" md="4" cols="8" @click="gotohome()" style="border-radius:10px; cursor:pointer; color:#FFFFFF; text-align:center; background:#142699; height:45px; margin-top:15px; padding:10px 0;">
                                    Back Home
                                </v-col>

                            </v-col>

                        </v-row>

                    </div>

                </v-col>

            </v-col>

        </v-row>

    </v-layout>

</div>

</template>

<script>
//import CustomCard from "../../../../components/CustomCard.vue";
//import downloadexcel from "vue-json-excel";


export default {
  data() {
    return {
      tes:"tes"
    }
  },
  methods: {
    gotohome(){
        window.location = "/#/?param="+localStorage.getItem("ip_uuid");
    }
  }
  //components: { CustomCard , downloadexcel },
  //components: { CustomCard },
};
</script>

<style>
</style>