<template>
  <div style="padding:2px 5px; margin:0!important auto;" id="middle-container">
    <div v-if="navstatus == false" style="position:absolute; top:0; width:100vw; height:100vh; left:0; background:rgba(50,50,50,0.5); z-index:10;"></div>

      <v-layout>

          <v-container>
              <v-row>

                <v-col sm="3" md="3" cols="3">
                  <v-icon v-if="$store.state.member.member.page != 'list'" @click="$store.state.member.member.page = 'list'">mdi-chevron-left</v-icon>
                </v-col>

                <v-col sm="6" md="6" cols="6" style="color:#000000; text-align:center; font-weight:bold;"> 
                  Secure Payment 
                </v-col>

                <v-col sm="3" md="3" cols="3"> 
                  <img src="../../assets/secure.png" width="60" style="float:right;" />
                </v-col>

              </v-row>
          </v-container>

      </v-layout>
      
      <v-layout>

        <v-row>

          <v-col sm="12" md="12" style="background:#FFF9DB; height:60px; margin:10px 0 0 0; padding:0;">

              <v-col sm="12" md="12" style="padding:10px 40px; margin-top:-5px;">

                    <div style="margin-right:10px; width:48px; height:48px; border-radius:50%; border:1px solid #CCCCCC; float:left;">
                      <img :src="detail_merchant != null ? detail_merchant.user.store_logo : ''" width="48" height="48" />
                    </div>

                    <div style="padding:5px 0;">
                        <h4 style="font-size:14px;"> {{ detail_merchant != null ? detail_merchant.user.store_name : "" }} </h4>
                        <h5 style="font-size:11px; font-weight:normal; margin-top:0;"> {{ detail_merchant != null ? detail_merchant.user.store_location : '' }}</h5>
                    </div>
                    
              </v-col>

          </v-col>
          
        </v-row>

      </v-layout>    
    
    <v-container style="padding:0; margin:10px 0 0 0;">
      <List v-if="$store.state.member.member.page == 'list'"></List>
      <Detail v-if="$store.state.member.member.page == 'detail'"></Detail>
      <DetailQris v-if="$store.state.member.member.page == 'detailqris'"></DetailQris>
      <NotFoundQris v-if="$store.state.member.member.page == '404'"></NotFoundQris>
    </v-container>
  </div>
</template>

<script>
//import Sidebar from "../layout/sidebar.vue";

import List from "./utama/awal.vue";
import DetailQris from "./utama/detailqris.vue";
import Detail from "./utama/detail.vue";
import NotFoundQris from "./utama/404.vue";

export default { 
  data() {
    return {
      pages:"list",
      profiles:"",
      detail_merchant:null,
      navstatus:false,
      longitudes:null,
      latitudes:null,
      dialog:true,
      user:null
    }
    },
    methods: {
      display_location_permission(){
        //alert("tes");
        window.open('chrome://settings/content/location');
      },
      async fetch(params) {
        this.$store.state.main.loading = true;
        this.loading = true;
        
        this.$store
          .dispatch("member/fetchDetailMember", {
              qrcode:params
        })
          .then(() => {
            //this.$store.commit("main/setLoading", false);
            this.$store.state.main.loading = false;
            this.loading = false;
            //this.loadingtext = '';

            // if(localStorage.getItem("ip_uuid") == null){
            //   localStorage.setItem("ip_uuid" , this.$route.query.param);
            // }
            // alert(process.env.VUE_APP_API_URL);
            this.detail_merchant = this.$store.state.member.member.listDetail;
        })
        .catch((res) => {
          
          //console.log("memberErrorsTrans" , res.response.data.status); 
          let messages_new = res.response.data.message;
          

          if(res.response.data.status == 403){
              messages_new = res.response.data.message + " Please scan again to get valid Qr";
          }

          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", messages_new);
          window.location = "/#/notfound";
          //this.$store.state.member.member.page = "404";
          // this.loading = false;
          // this.loadingtext = '';
        });
      },
      foundLocation(position){
        let lat = position.coords.latitude;
        let long = position.coords.longitude;

        this.longitudes = long;
        this.latitudes  = lat;

        localStorage.setItem("longitude_user" , long); 
        localStorage.setItem("latitude_user" , lat);

        this.navstatus = true;
        
        },
        noLocation(){
            //this.$store.commit("main/setSnackbarFail", "Please turn on location for this payment");
            this.dialog = true;
        },
        gotocoordinate(){
            navigator.geolocation.getCurrentPosition(this.foundLocation, this.noLocation);
      },
  },
  mounted() {
    this.gotocoordinate();
    //localStorage.setItem("ip_uuid" , this.$route.query.param);
    if(this.$route.query.param != null){
      localStorage.setItem("ip_uuid" , this.$route.query.param);
    }

    if (navigator.geolocation) {
      //alert("true");
      this.dialog = false;
    }
    
    // navigator.permissions.query({name:'geolocation'}).then(function(result) {
    //   if(result.state == "granted"){
    //     this.dialog = false;
    //   }
    // });
    
    this.fetch(this.$route.query.param);

    window.history.pushState({}, document.title, "/");
    //alert(localStorage.getItem("ip_uuid"));

  },
  components: { List, Detail , DetailQris , NotFoundQris } 
};
</script>

<style>

  .hidden{
    display:none;
  }

  .localhidden{
    display:none;
    padding:0 15px;
  }

  .flexs{
    display:inline;
  }

  .inlinepadding{
    /* padding:0 15px; */
    display:inline;
  }

  .flexbox{
    display:flex;
  }

  .barheight {
    height: 64px;
  }
  
  .active-group {
    background-color: #ffffff54;
  }
  
  .tile:hover {
    background-color: #ffffff30;
  }
  
  .v-list-group__items .v-list-item__icon {
      padding-left:20px!important;
  }

  .v-application .mt-5{
    margin-top:0!important;
  }
  
  @media only screen and (max-width: 899px) {
    #middle-container {
        width:100%;
        margin:auto;
        max-height:100vh;
        height:100vh;
        overflow:hidden;
        box-shadow:0 0 2px 2px #CCCCCC;
        padding:5px 0;
        position:relative;
    }

    #middle-blue-container {
        width:100%;
        margin:auto;
        max-height:100vh;
        height:100vh;
        overflow:hidden;
        background:#142699;
        padding:5px 0;
        position:relative;
    }
  }

  @media only screen and (min-width: 900px) {
      #middle-container {
          width:480px;
          margin:auto;
          max-height:100vh;
          height:100vh;
          overflow:hidden;
          box-shadow:0 0 2px 2px #CCCCCC;
          padding:5px 0;
          position:relative;
      }
  }

  .channels-style{
    color:#868E96; 
    font-size:15px; 
    padding:8px 0; 
    font-weight:normal;
  }

  .hovering{
    font-weight:bold!important;
    font-size:16px!important;
  }

  .channels-active{
    font-weight:bold!important;
    color:#666666; 
    font-size:17px!important; 
    padding:8px 0; 
  }

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 6px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #bbbbbb;
    background-color: #e7e6e7;
    border-radius: 50%;
  }

  input[type="radio"]:checked {
    background-color: #93e026;
  } 
  
  #footers{
      position:absolute;
      z-index:1;
      box-shadow: 0px -8px 12px rgba(0, 0, 0, 0.03);
      height:9.8vh;
      width:100%;
      background:#FFFFFF;
      padding:2px 10px 2px 20px;
      box-sizing:border-box;
      bottom:0;
      left:0;
  }

  #footersDetail{
      position:absolute;
      z-index:1;
      box-shadow: 0px -8px 12px rgba(0, 0, 0, 0.03);
      height:12.8vh;
      width:100%;
      background:#142699;
      padding:0 0 20px 0;
      box-sizing:border-box;
      bottom:0;
      left:0;
  }
  
  #prices{
      /* font-family: 'Lato'; */
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      letter-spacing: 0.02em;
      margin-top:-2px;
  }
  
  </style>