<template>

    <div>
        <v-layout>
            <v-row style="margin-top:20px;">

                    <v-col sm="12" md="12" style="border-radius:20px 20px 0 0; background:#142699; box-shadow: 0 0 2px 2px #CCCCCC; height:80vh; width:100%; padding:0 0; box-sizing:border-box; overflow:auto">
                        <div style="padding:10px 40px; box-sizing:border-box; background:#FFFFFF; border-radius:20px 20px 0 0; height:48px;">
                            <h5 style="font-size:16px; color:#142699; font-weight:bold;"> Instant Payment </h5>
                        </div>

                        <div style="margin-top:10px; background:#142699; height:65.5vh; width:100%; padding:20px 10px; box-sizing:border-box; overflow:auto;">
                            
                            <div>

                            <div style="background:rgba(242, 247, 245, 0.5); height:30px; padding:4px; width:95%; margin:auto; border-radius:10px;  margin-right:10px; margin-top:0;">
                                <center style="font-weight:bold;"> Please Fill Form Below </center>
                            </div>

                            <div style="padding:10px 15px; width:100%; height:auto; border-radius:10px;  margin-right:10px; margin-top:10px;">
                                
                                <v-col sm="12" md="12" style="padding:0; margin:0;">
                                    <!-- <input type="text" class="form-control" placeholder="Name" style="padding:25px 10px;" /> -->
                                    <v-text-field
                                        v-model="form.name"
                                        label="Name"
                                        placeholder="Name"
                                        solo>
                                    </v-text-field>

                                    <div v-if="name_error == true" style="font-size:11px; color:#ff0000; margin-top:-25px; margin-bottom:20px; font-weight:bold;"> 
                                        {{ name_error_text }}
                                    </div>
                                    <!-- <div style="margin-top:-30px;"> <small style="color:#ff0000;"> Nama Lengkap </small> </div> -->
                                </v-col>

                                <v-col sm="12" md="12" style="padding:0; margin:-10px 0 0 0;">

                                    <v-text-field
                                        v-model="form.email"
                                        label="Email"
                                        placeholder="Email"
                                        solo>
                                    </v-text-field>

                                    <div v-if="email_error == true" style="font-size:11px; color:#ff0000; margin-top:-25px; margin-bottom:20px; font-weight:bold;"> 
                                        {{ email_error_text }}
                                    </div>

                                </v-col>

                                <v-col sm="12" md="12" style="padding:0; margin:-10px 0 0 0;">
                                    
                                    <v-text-field
                                        v-model="form.phone"
                                        label="Phone"
                                        placeholder="Phone"
                                        solo>
                                    </v-text-field>

                                    <div v-if="phone_error == true" style="font-size:11px; color:#ff0000; margin-top:-25px; margin-bottom:20px; font-weight:bold;"> 
                                        {{ phone_error_text }}
                                    </div>

                                </v-col>

                                <v-col sm="12" md="12" style="padding:0; margin:-10px 0 0 0;">
                                    
                                    <v-text-field
                                        v-model="form.amount_trans"
                                        label="Amount Transaction"
                                        placeholder="Amount"
                                        @keyup="change_amount"
                                        type="number"
                                        inputmode="numeric"
                                        solo>
                                    </v-text-field>

                                    <div v-if="amount_error == true" style="font-size:11px; color:#ff0000; margin-top:-25px; margin-bottom:20px; font-weight:bold;"> 
                                        {{ amount_error_text }}
                                    </div>

                                </v-col>

                                <v-col sm="12" md="12" style="padding:0; margin:-10px 0 0 0;">
                                    
                                    <v-select
                                        :items="payment_type"
                                        item-text="text"
                                        item-value="value"
                                        @change="pick_payment()"
                                        dense
                                        outlined
                                        class="payment_type"
                                        v-model="form.payment_type"></v-select>

                                    <div v-if="amount_error == true" style="font-size:11px; color:#ff0000; margin-top:-25px; margin-bottom:20px; font-weight:bold;"> 
                                        {{ amount_payment_type }}
                                    </div>

                                </v-col>

                                <v-col sm="12" md="12" style="padding:0; margin:-10px 0 0 0;">

                                    <v-text-field
                                        v-model="form.notes" 
                                        label="Transaction Note"
                                        placeholder="Note"
                                        solo>
                                    </v-text-field>

                                </v-col>

                            </div>

                            <div style="width:93%; margin:10px auto 0 auto;">
                                <h5 style="font-size:13px; font-weight:bold; color:#FFFFFF;">Payment </h5>
                            </div>


                            <div style="padding:0 15px; margin-top:20px;">
                                <div style="border-radius:5px; background:#FFFFFF; box-shadow:0 0 1px 1px #999999;">

                                    <div style="background:#FFFFFF; min-height:65px; padding:10px; width:95%; margin:auto; border-radius:10px;  margin-right:10px;">
                                        
                                        <v-row>
                                            <v-col cols="2" sm="2" md="2">
                                                <img src="../../../assets/interntrans.png" style="float:left; margin-right:10px; width:40px;"  v-if="this.picked_payment == 'inter'" />
                                                <img src="../../../assets/image14.png" style="margin-top:5px; width:40px;" v-if="this.picked_payment == 'local'" />
                                                <img src="../../../assets/question1.png" style="margin-top:5px; width:40px;" v-if="this.picked_payment == ''" />
                                            </v-col>

                                            <v-col cols="8" sm="8" md="8">
                                                <div style="padding:5px 0;">
                                                    <h5 style="font-size:13px;"> {{ select_payment }} </h5>
                                                    <img src="../../../assets/gbrinter.png" style="margin-top:5px;  width:90%;" v-if="this.picked_payment == 'inter'" />
                                                    <img src="../../../assets/gbrlocal.png" style="margin-top:5px; width:90%;" v-if="this.picked_payment == 'local'" />
                                                    <div v-if="this.picked_payment == ''" style="font-size:11px;">Accept Local & International Transaction</div>
                                                </div>
                                            </v-col>

                                            <v-col cols="2"  sm="2" md="2" style="padding:10px 0; margin:0;" @click="expand_view_head()">
                                                <v-icon style="height:48px; font-size:32px; float:right;  cursor:pointer;">{{ chevron_tops }}</v-icon>
                                            </v-col>

                                        </v-row>

                                    </div>

                                <div :class="expanded_header_trans">

                                    <div style="width:100%; border-top:1px solid #CCCCCC;"></div>

                                        <div style="background:#F5F5F5; opacity:0.9; padding:10px 10px; box-sizing:border-box;" @click="pick_trans(0)" v-if="this.$store.state.member.member.hasPaymentInter > 0">

                                            <v-row>
                                                <v-col cols="3" sm="2" md="2">

                                                    <div style="padding:5px; background:#FFFFFF; border-radius:4px; height:55px; float:left; width:auto; margin-right:20px;">
                                                        <img src="../../../assets/interntrans.png" style="width:40px;"  />
                                                    </div>

                                                </v-col>

                                                <v-col cols="7" sm="8" md="8">

                                                    <div style="color:#868E96; font-size:14px; padding:12px 0; font-weight:bold;">
                                                        International Transaction
                                                    </div>

                                                </v-col>

                                                <v-col cols="2" sm="2" md="2">
                                                    <input type="radio" id="localtrans1" value="inter" v-model="picked_payment" @click="pick_trans(0)" style="margin:15px 0; float:right; margin-right:15px;" />
                                                </v-col>

                                            </v-row>

                                        </div>

                                        <div style="width:100%; border-top:1px solid #CCCCCC;"></div>


                                        <div style="background:#F5F5F5; opacity:0.9; padding:10px 10px; box-sizing:border-box;" @click="pick_trans(1)">

                                            <v-row>
                                                <v-col cols="3" sm="2" md="2">

                                                    <div style="padding:5px; background:#FFFFFF; border-radius:4px; height:55px; float:left; width:auto; margin-right:20px;">
                                                        <img src="../../../assets/image14.png" style="width:40px;" />
                                                    </div>

                                                </v-col>

                                                <v-col cols="7" sm="8" md="8">

                                                    <div style="color:#868E96; font-size:14px; padding:12px 0; font-weight:bold;">
                                                        Local Transaction
                                                    </div>

                                                </v-col>

                                                <v-col cols="2" sm="2" md="2">
                                                    <input type="radio" id="localtrans2" value="local" v-model="picked_payment" @click="pick_trans(1)" style="margin:15px 0; float:right; margin-right:15px;" />
                                                </v-col>

                                            </v-row>

                                        </div>

                                    </div>


                                </div>

                            </div>

                            <div :class="hidpayment" style="background:rgba(242, 247, 245, 0.5); height:30px; padding:4px; width:95%; margin:auto; border-radius:10px;  margin-right:10px; margin-top:20px;">
                                <center style="font-weight:bold;"> - Please Select Payment Method - </center>
                            </div>

                            <!-- <div v-for="detail_merchant.payment_types[0].payment_methods of items"> -->
                            <template v-for="(item, i) in $store.state.member.member.listPaymentInter">

                                <div @click="pick_channels_inters(item.payment_channels[0].payment_channel_code , 0 , i); set_payment(item.payment_channels[0].payment_method_code , item.payment_channels[0].payment_channel_code)" :class="viewInter" :key="i" style="background:#F5F5F5; opacity:0.9; border:5px solid #5D71F1; height:60px; padding:6px 10px; width:95%; margin:auto; border-radius:10px;  margin-right:10px; margin-top:10px;">
                                    
                                    <!-- <div style="padding:5px; background:#FFFFFF; border-radius:4px; min-height:28px; float:left; width:auto; margin-right:20px;">
                                        <img :src="item.payment_channels[0].logo" style="height:30px;" />
                                    </div>

                                    <div style="color:#868E96; font-size:15px; padding:8px 0; font-weight:normal;">
                                        {{ item.name }}
                                    </div> -->

                                    <v-row>
                                        
                                        <v-col sm="2" md="2" cols="2">
                                            <div style="padding:5px; box-shadow:0 0 1px 1px #DDDDDD; background:#FFFFFF; border-radius:4px; height:36px; float:left; width:auto; margin-right:20px;">
                                                <img :src="item.payment_channels[0].logo" style="height:30px; width:30px; object-fit:cover;" />
                                            </div>
                                        </v-col>

                                        <v-col sm="8" md="8" cols="8">
                                            <div :class="'channels parent-'+i+' channels-list-'+a+' channels-style'" style="font-size:12px;">
                                                {{ item.name }} 
                                            </div>
                                        </v-col>

                                        <v-col sm="2" md="2" cols="2">
                                            <input type="radio" @click="set_payment(item.payment_channels[0].payment_method_code , item.payment_channels[0].payment_channel_code)"  :value="item.payment_channels[0].payment_channel_code" v-model="picked" style="margin:10px 0; float:right; margin-right:15px;" />
                                        </v-col>

                                    </v-row>
                                        
                                </div>
                                <!-- <div :class="viewInter" style="background:#F5F5F5; opacity:0.9; border:5px solid #5D71F1; height:60px; padding:6px 10px; width:95%; margin:auto; border-radius:10px;  margin-right:10px; margin-top:10px;">
                                    
                                    <div style="padding:5px; background:#FFFFFF; border-radius:4px; height:28px; float:left; width:auto; margin-right:20px;">
                                        <img src="../../../assets/cross-border.png" />
                                    </div>

                                    <div style="color:#868E96; font-size:15px; padding:8px 0; font-weight:normal;">
                                        Wallet Anda
                                    </div>
                                        
                                </div> -->
                            </template>
                            <!-- <v-radio-group v-model="radioGroup"> -->
                                
                            <div :class="viewLocal">

                            <div style="padding:0 15px;">

                            <template v-for="(item, i) in $store.state.member.member.listPaymentLocalChannel">

                                <div :key="i" style="border-radius:5px; background:#F5F5F5; box-shadow:0 0 1px 1px #999999; margin-top:20px;">

                                    <div style="border-radius:5px 5px 0 0; background:#FFFFFF; height:40px; padding:10px; box-sizing:border-box; width:100%; box-shadow:0 0 1px 1px #CCCCCC;">
                                            <v-row>

                                                <v-col cols="8" sm="8" md="8">
                                                    <h5 style="color:#142699; font-size:12px; "> {{ item.name }} </h5>
                                                </v-col>

                                                <v-col cols="3" sm="3" md="3">
                                                    <img src="../../../assets/subright1.png" style="float:right;" />
                                                </v-col>

                                                <v-col cols="1" sm="1" md="1">
                                                    <v-icon style="font-size:32px; float:right; margin-top:-7px;">mdi-chevron-down</v-icon>
                                                </v-col>

                                            </v-row>
                                    </div>

                                    <template v-for="(child, a) in item.payment_channels">         

                                        <div :key="a"  @click="pick_channels(child.payment_channel_code , a , i); set_payment(child.payment_method_code , child.payment_channel_code)" style="background:#F5F5F5; opacity:0.9; height:auto; padding:10px 10px; width:95%; margin:auto; border-radius:10px;  margin-right:10px; margin-top:10px;">
                                            
                                            <v-row>

                                                <v-col sm="3" md="3" cols="3" style="padding:10px 0 0 0; margin:0;">

                                                    <div style="padding:5px; box-shadow:0 0 1px 1px #DDDDDD; background:#FFFFFF; border-radius:4px; height:auto; float:left; width:auto; margin-right:20px;">
                                                        <img :src="child.logo" style="width:100%; margin:5px 0 0 0;" />
                                                    </div>

                                                </v-col>

                                                <v-col sm="7" md="7" cols="7" style="padding:10px 0 0 0; margin:0;">

                                                    <div :class="'channels parent-'+i+' channels-list-'+a+' channels-style'">
                                                        {{ child.payment_channel_name }} 
                                                    </div>

                                                </v-col>

                                                <v-col sm="2" md="2" cols="2" style="margin:0; padding:10px 0 0 0; justify-content: flex-end; align-items:flex-end;">
                                                    <input type="radio" @click="set_payment(child.payment_method_code , child.payment_channel_code)"  :value="child.payment_channel_code" v-model="picked" style="margin:10px 0; float:right;" />
                                                </v-col>

                                                <v-col sm="12" md="12" cols="12" style="border-top:1px solid #CCCCCC; margin:15px 0 0 0; padding:0!important;">

                                                </v-col>

                                            </v-row>
                                            
                                            
                                                
                                        </div>

                                    </template>
                                
                                </div>

                            </template>
                                
                        </div>
                     </div>
                    </div>
                            
                </div>
            </v-col>

        </v-row>

    </v-layout>

    <v-layout>

        <div id="footers">
            <div style="width:100%;">

                <div style="padding:10px 5px; float:left;">
                    <h5 style="font-size: 13px;"> Total</h5>
                    <h5 id="prices"> Rp. {{ amount_transaksi }}</h5>
                </div>

                <div style="float:right; width:40%; padding:5px 0; box-sizing:border-box;">
                    <v-btn  color="success" style="width:100%; height:55px;" @click="pay_inter()">
                        Pay Now
                    </v-btn>
                </div>

            </div>
        </div>

        <!-- <div style="background:#F2F7F5; width:100%; height:45px; padding:5px 0; position:absolute; bottom:0; left:0;">
                <v-col sm="12" md="12">

                    <v-row class="justify-content-center">

                        <v-col sm="3" md="3" style="text-align:center;"> A </v-col>
                        <v-col sm="3" md="3" style="text-align:center;"> B </v-col>
                        <v-col sm="3" md="3" style="text-align:center;"> C </v-col>
                        <v-col sm="3" md="3" style="text-align:center;"> D </v-col>

                    </v-row>

                </v-col>
        </div> -->

    </v-layout>

    </div>

</template>

<script>
//import CustomCard from "../../../../components/CustomCard.vue";
//import downloadexcel from "vue-json-excel";


export default {
  data() {
    return {
      tes:"tes",
      a:"",
      radioGroup: 1,
      column:null,
      column1:null,
      picked:[],
      picked2:null,
      viewLocal:"hidden",
      viewInter:"hidden",
      expanded_header_trans:"hidden",
      chevron_tops:"mdi-chevron-down",
      select_payment:"Select Payment Method",
      hidpayment:"",
      picked_payment:"",
      payment_method_name:"",
      payment_channel_name:"",
      amount_transaksi:"0",
      hovering:[],
      payment_type:[
        { text: "Select Payment Type", value: "0" },
        { text: "Pembelian", value: "Pembelian" },
        { text: "Pembayaran", value: "Pembayaran" }
      ],
      isHovering:false,
      active_text_channels:"channels-style",
      detail_merchant:null,
      last_index_hover:null,
      channels_picked:null,
      last_index_hover_cc:null,
      channels_picked_cc:null,
      navigator:"nav",
      error_check:0,
      phone_error:false,
      phone_error_text:"",
      amount_error:false,
      amount_error_text:"",
      amount_payment_type:"",
      pilihan_payment_type:"0",
      email_error:false,
      email_error_text:"",
      name_error:false,
      name_error_text:"",
      longitudes:0,
      latitudes:0,
      img_bottom_payment:"../../../assets/slide-intern.png",
      responses:[],
      form:{
        name:""
      }
    };
  },
  methods: {
    formatRupiah(angka, prefix){
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

        let separator = "";
    
        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if(ribuan){
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
    
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    },
    pick_payment(){
        this.pilihan_payment_type = this.form.payment_type
        // if(this.form.payment_type == "Lainnya"){
        //     this.form.notes = "";
        // }
        // else{
        //     this.form.notes = this.form.payment_type;
        // }
    },
    expand_view_head(){

        this.expanded_header_trans = this.expanded_header_trans == "hidden" ? "flexs" : "hidden";
        this.chevron_tops = this.chevron_tops == "mdi-chevron-down" ? "mdi-chevron-up" : "mdi-chevron-down";
        
    },
    pick_trans(values){

        if(values == 0){
            this.select_payment = "International Transaction";
            this.picked_payment = "inter"
            this.viewLocal = "hidden"
            this.viewInter = "flexbox"
            this.hidpayment = "hidden"
            this.img_bottom_payment = "../../../assets/gbrinter.png"
        }
        else{
            this.select_payment = "Local Transaction";
            this.picked_payment = "local"
            this.viewInter = "hidden"
            this.viewLocal = "inlinepadding"
            this.hidpayment = "hidden"
            this.img_bottom_payment = "../../../assets/gbrlocal.png"
        }
        
    },
    pick_channels(vals , index , parent){
        if(this.last_index_hover != null){
            //this.hovering[this.last_index_hover] = null;
            document.querySelector(this.channels_picked).classList.remove("channels-active");
        }

        this.last_index_hover = index;
        this.channels_picked = ".parent-"+parent+".channels-list-"+this.last_index_hover;

        this.picked = vals;
        this.active_text_channels = "channels-active";
        this.isHovering = true;
       
        document.querySelector(".parent-"+parent+".channels-list-"+index).classList.add("channels-active");
        //this.hovering[index] = true;

    },
    pick_channels_inters(vals , index){
        if(this.last_index_hover != null){
            //this.hovering[this.last_index_hover] = null;
            document.querySelector(this.channels_picked).classList.remove("channels-active");
        }

        this.last_index_hover = index;
        this.channels_picked = ".parent-"+index+".channels-list-"+this.last_index_hover;

        this.picked = vals;
        this.active_text_channels = "channels-active";
        this.isHovering = true;
       
        document.querySelector(".parent-"+index+".channels-list-"+index).classList.add("channels-active");
        //this.hovering[index] = true;

    },
    set_payment(method_code , channel_code){
        this.payment_method_name = method_code;
        this.payment_channel_name = channel_code;
        //alert(this.payment_method_name);
    },
    pay_inter(){
        this.error_check = 0;

        if(this.form.phone == ""){
            this.phone_error = true;
            this.error_check = 1;
            this.phone_error_text = "Data Phone is Required";
        }
        else{
            this.phone_error = false;
        }

        if(this.form.email == ""){
            this.email_error = true;
            this.error_check = 1;
            this.email_error_text = "Data Email is Required";
        }
        else{
            this.email_error = false;
        }

        if(this.form.name == ""){
            this.name_error = true;
            this.error_check = 1;
            this.name_error_text = "Data Name is Required";
        }
        else{
            this.name_error = false;
        }

        if(this.form.amount_trans == undefined){
            this.amount_error = true;
            this.error_check = 1;
            this.amount_error_text = "Data Amount is Required";
        }
        else{
            this.amount_error = false;
        }

        if(this.form.payment_type == "0"){
            this.amount_error = true;
            this.error_check = 1;
            this.amount_payment_type = "Data Payment Type is Required";
        }
        else{
            this.amount_error = false;
        }
        //alert(this.form.amount_trans);


        if(this.error_check > 0){
            return false;
        }
        //alert(this.payment_channel_code);
        // if(this.payment_method_name == 'qris'){
        //     this.$store.state.member.member.page = "detailqris";
        // }
        // else{
        //     this.$store.state.member.member.page = "detail";
        // }
        //alert(method_code + " = " + channel_code);
        this.$store.commit("main/setLoading", true);
        // this.loading = true;
        // this.loadingtext = 'Loading... Please wait';
        localStorage.setItem("ip_name" , this.form.name);
        localStorage.setItem("ip_email" , this.form.email);
        localStorage.setItem("ip_phone" , this.form.phone);
        //localStorage.setItem("ip_phone" , this.form.phone);

        //alert(process.env.VUE_APP_BASE_URL+"/cancel");

        this.$store
            .dispatch("member/postCheckout", {
                qr_id:this.$route.query.param,
                name:this.form.name,
                phone:this.form.phone,
                email:this.form.email,
                amount:this.form.amount_trans,
                notes:this.form.notes != "" && this.form.notes != undefined ? this.form.payment_type + " - " + this.form.notes : this.form.payment_type,
                payment_method:this.payment_method_name,
                payment_channel:this.payment_channel_name,
                long:localStorage.getItem("longitude_user"),
                lat:localStorage.getItem("latitude_user"),
                success_url:process.env.VUE_APP_BASE_URL+"/success",
                cancel_url:process.env.VUE_APP_BASE_URL+"/cancel"
            })
            .then(() => {
            
            // this.loading = false;
            // this.loadingtext = '';

            this.responses = this.$store.state.member.member.paymentRes;
            this.$store.commit("main/setLoading", false);
            
            if(this.responses.payment_detail.payment_url != null){
                window.location = this.responses.payment_detail.payment_url;
            }

            else{
                //this.$store.commit("main/setLoading", false);
                if(this.$store.state.main.loading == false){

                    if(this.payment_method_name == 'qris'){
                        //this.$store.commit("main/setLoading", false);
                        this.$store.state.member.member.page = "detailqris";
                    }
                    else{
                        //this.$store.commit("main/setLoading", false);
                        this.$store.state.member.member.page = "detail";
                    }

                }

            }

            this.form.amount_trans = "";
            this.payment_method_name = "";
            this.payment_channel_name = "";
            this.form.notes = "";
            
            //this.detail_merchant = this.$store.state.member.member.listDetail;
            })
            .catch((res) => {
            //alert(this.$store.state.member.member.resError.data.message);
            //console.log("memberErrors Transact" , "new trans");
            // alert("member trans error");
            
            let messages_new = res.response.data.message;
          

            if(res.response.data.status == 403){
                messages_new = res.response.data.message + " Please scan again to get valid Qr";
            }

            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", messages_new);
            //this.$store.commit("main/setSnackbarFail", res.message);
            // this.loading = false;
            // this.loadingtext = '';
        });

    },
    change_amount(){
        let rupiah = this.formatRupiah(this.form.amount_trans);
        this.amount_transaksi = rupiah;
    },
  },
  mounted() {
    //if (!navigator.geolocation){
       // this.gotocoordinate();
    //}
    
   //let nav = this.gotocoordinate();

    //console.log(nav);
    this.form.payment_type = "0";

    this.form.name  = localStorage.getItem("ip_name") === "null" ? "" : localStorage.getItem("ip_name");
    this.form.email = localStorage.getItem("ip_email") === "null" ? "" : localStorage.getItem("ip_email");
    this.form.phone = localStorage.getItem("ip_phone") === "null" ? "" : localStorage.getItem("ip_phone");
  },
  watch: {
    gotocoordinate(){
        alert(localStorage.getItem("location_status"));
    },
    // pagination() {
    //     //alert(this.$store.state.member.member.is_back);
    //     if(this.$store.state.member.member.is_back != 1){
    //         this.loading = true
    //         this.loadingtext = 'Loading... Please wait'
    //         // alert("tes");
    //         //console.log("user_con" , "nils "+this.profiles.id);
    //         //alert("tes");
            
    //         this.fetch(0)
    //     }

    //     if(this.$store.state.member.member.is_back == 1){
    //         this.pagination.page = this.$store.state.member.member.paging_page;
    //     }

    //     this.$store.state.member.member.is_back = 0;

    // },
    // searchSponsor(val) {
    //     val && val !== this.searchForm.sponsor && this.getSponsor(val);
    // },
    // searchMember(val) {
    //     val && val !== this.searchForm.member && this.getMember(val);
    // }

  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  //components: { CustomCard , downloadexcel },
  //components: { CustomCard },
};
</script>

<style>

.payment_type .v-input__slot {
background-color: #FFFFFF!important;
color:#FFFFFF!important;
padding:15px;
height:40px;
}

.payment_type .v-text-field__slot input {
   color: #999999 !important;
}

.payment_type .v-text-field__slot input::placeholder {
  color: #666666!important;
}

</style>