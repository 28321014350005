<template>

    <div style="padding:0; margin:0!important auto;" id="middle-blue-container">

            <v-layout>
    
                <v-container style="background:#FFFFFF;">
                    <v-row>
                    <v-col sm="3" md="3" cols="3">
                        <v-icon v-if="$store.state.member.member.page != 'list'" @click="$store.state.member.member.page = 'list'">mdi-chevron-left</v-icon>
                    </v-col>
                    <v-col sm="6" md="6" cols="6" style="color:#000000; text-align:center; font-weight:bold;"> Secure Payment </v-col>
                    <v-col sm="3" md="3" cols="3"> <img src="../../assets/secure.png" width="60" style="float:right;" /></v-col>
                    </v-row>
                </v-container>
    
            </v-layout>
            
            <v-layout>
    
            <v-row>
    
                <v-col sm="12" md="12" style="background:#FFF9DB; height:60px; margin:10px 0 0 0; padding:0;">
                    
                </v-col>
                
            </v-row>
    
            </v-layout>    
        
        <v-container style="padding:0; margin:10px 0 0 0;">
            <CancelTrans></CancelTrans>
        </v-container>

    </div>

  </template>
  
  <script>
  //import Sidebar from "../layout/sidebar.vue";
  import CancelTrans from "./utama/cancel.vue";
  
  export default { 
    data() {
        return {
            pages:"list",
            profiles:"",
            detail_merchant:null
        }
    },
    mounted() {
        //alert("tes founded");
    },
        components: { CancelTrans } 
};
  </script>
  
  <style>

    .hidden{
      display:none;
    }
  
    .localhidden{
      display:none;
      padding:0 15px;
    }
  
    .flexs{
      display:inline;
    }
  
    .inlinepadding{
      /* padding:0 15px; */
      display:inline;
    }
  
    .flexbox{
      display:flex;
    }
  
    .barheight {
      height: 64px;
    }
    
    .active-group {
      background-color: #ffffff54;
    }
    
    .tile:hover {
      background-color: #ffffff30;
    }
    
    .v-list-group__items .v-list-item__icon {
        padding-left:20px!important;
    }
  
    .v-application .mt-5{
      margin-top:0!important;
    }
    
    @media only screen and (max-width: 899px) {
      #middle-container {
          width:100%;
          margin:auto;
          max-height:100vh;
          height:100vh;
          overflow:hidden;
          box-shadow:0 0 2px 2px #CCCCCC;
          padding:5px 0;
          position:relative;
      }

      #middle-blue-container {
            width:100%;
            margin:auto;
            max-height:100vh;
            height:100vh;
            overflow:hidden;
            box-shadow:0 0 2px 2px #CCCCCC;
            background:#142699;
            padding:5px 0;
            position:relative;
        }

    }
  
    @media only screen and (min-width: 900px) {
        #middle-container {
            width:480px;
            margin:auto;
            max-height:100vh;
            height:100vh;
            overflow:hidden;
            box-shadow:0 0 2px 2px #CCCCCC;
            padding:5px 0;
            position:relative;
        }

        #middle-blue-container {
            width:480px;
            margin:auto;
            max-height:100vh;
            height:100vh;
            overflow:hidden;
            box-shadow:0 0 2px 2px #CCCCCC;
            background:#142699;
            padding:5px 0;
            position:relative;
        }
    }
  
    .channels-style{
      color:#868E96; 
      font-size:15px; 
      padding:8px 0; 
      font-weight:normal;
    }
  
    .hovering{
      font-weight:bold!important;
      font-size:16px!important;
    }
  
    .channels-active{
      font-weight:bold!important;
      color:#666666; 
      font-size:17px!important; 
      padding:8px 0; 
    }
  
    input[type='radio'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* create custom radiobutton appearance */
      display: inline-block;
      width: 25px;
      height: 25px;
      padding: 6px;
      /* background-color only for content */
      background-clip: content-box;
      border: 2px solid #bbbbbb;
      background-color: #e7e6e7;
      border-radius: 50%;
    }
  
    input[type="radio"]:checked {
      background-color: #93e026;
    } 
    
    #footers{
        position:absolute;
        z-index:1;
        box-shadow: 0px -8px 12px rgba(0, 0, 0, 0.03);
        height:9.8vh;
        width:100%;
        background:#FFFFFF;
        padding:2px 10px 2px 20px;
        box-sizing:border-box;
        bottom:0;
        left:0;
    }
  
    #footersDetail{
        position:absolute;
        z-index:1;
        box-shadow: 0px -8px 12px rgba(0, 0, 0, 0.03);
        height:12.8vh;
        width:100%;
        background:#142699;
        padding:0 0 20px 0;
        box-sizing:border-box;
        bottom:0;
        left:0;
    }
    
    #prices{
        /* font-family: 'Lato'; */
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */
        letter-spacing: 0.02em;
        margin-top:-2px;
    }
    
</style>