const { default: axios } = require("axios");
const { apiUrl } = require("../api/apiUrl")
//import store from '../store'

const service = axios.create({
    baseURL: apiUrl,
    timeout: 100000,
    method: 'POST'
})

service.interceptors.request.use(
    (config) => {
        // store.commit('setIsAdmin', true)
        //store.isAdmin = true
        config.headers['content-Type'] = 'multipart/form-data'
        return config
    },
    (error)=> {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response) => {
        // store.commit('setIsAdmin', true)
        //store.isAdmin = true
        const res = response.data
        return res
    },
    (error) => {
        
        return Promise.reject(error)
    }
)

export default service