<template>
     <div>
    
        <v-layout>

            <v-row style="margin-top:20px;">

                <v-col sm="12" md="12" style="border-radius:20px 20px 0 0; box-shadow: 0 0 2px 2px #CCCCCC; height:80vh; width:100%; padding:10px 0; box-sizing:border-box; overflow:auto">
                    <div style="padding:0 40px;">
                        <h5 style="font-size:16px; color:#142699; font-weight:bold;"> Payment Detail #009120192</h5>
                    </div>

                    <div style="margin-top:20px; background:#F3F3F3; height:63vh; width:100%; padding:20px 10px; box-sizing:border-box; overflow:auto;">
                            
                          <v-layout justify-center>

                                <div style="position:relative; width:70%;">

                                  <div style="position:absolute; top:6em; font-size:12px; left:10%; text-align:center; width:80%; right:10%">
                                      <div> {{ this.$store.state.member.member.listDetail.user.store_name }} </div>
                                      <div style="font-size:10px;"> NM ID : {{ responses.payment_detail.nmid }} </div>
                                      <div style="font-size:10px;">  {{ responses.payment_detail.terminal }} </div>
                                  </div>

                                  <img src="../../../assets/qris_template-5.png" style="width:100%; cursor:pointer;" />
                                
                                  <qrcode-vue :value="responses.payment_detail.payment_code" :size="size" level="H" style="position:absolute; top:8em; left:20%;" />

                                </div>

                          </v-layout>

                          <!-- <v-layout justify-center>
                              
                              <div style="border-top:1px solid #CCCCCC; width:250px; margin-top:10px;"></div>
                              
                          </v-layout> -->

                          <!-- <v-layout justify-center>
                              
                              <div>
                                  <h5 style="font-size:12px; color:#808080; margin-top:20px;"> Please transfer to account below: </h5> <p></p>
                                  <h4 style="font-size:14px; color:#262626; font-weight:normal; margin:-10px 0 0 0;">Bank BPD BALI Kode (256400)</h4>
                              </div>
                              
                          </v-layout>

                          <v-layout justify-center style="margin-top:20px;">

                              <v-col sm="10" md="10"> 

                              <v-row>

                                  <v-col sm="10" md="10" style="margin:0; padding:0;">

                                      <v-text-field
                                          v-model="copyvalue"
                                          label="Copy Value"
                                          placeholder="Placeholder"
                                          solo>
                                      </v-text-field>

                                  </v-col>

                                  <v-col sm="2" md="2" style="background:#DADADA; margin:0; padding:0; height:48px; padding:14px 0; box-sizing:border-box; text-align:center;">
                                          <h5 style="color:#666666;">Copy</h5>
                                  </v-col>

                              </v-row>

                              </v-col>

                          </v-layout> -->



                          <!-- <v-layout justify-center style="margin-top:-20px;">
                              
                              <div>
                                  <h5 style="font-size:14px; color:#262626;"> Ipaymu Pak Rieke </h5> <p></p>
                              </div>
                              
                          </v-layout>

                          <v-layout justify-center style="margin-top:-20px;">
                              
                              <div style="border-top:1px solid #CCCCCC; width:250px; margin-top:10px;"></div>
                              
                          </v-layout> -->

                          <v-layout justify-center>
                              
                              <div style="text-align:center;  margin-top:1.2em;">
                                  <h5 style="font-size:12px; color:#808080; margin-top:10px;"> Amount </h5> <p></p>
                                  <h4 style="font-size:18px; color:#262626;  margin:-15px 0 0 0;">Rp. {{ responses.payment_detail.total_idr }}</h4>

                                  <h5 style="font-size:12px; color:#808080; margin-top:10px;"> Payment Expired </h5> <p></p>
                                  <h4 style="font-size:14px; color:#FF0000;  margin:-10px 0 0 0;">{{ responses.payment_detail.expired_format }}</h4>

                                  <p></p>
                                  <small>Scan QR with your electronic wallet application.</small>
                                  <div> <img src="../../../assets/imagecr.png" /> </div>
                              </div>
                              
                          </v-layout>

                    </div>

                </v-col>

            </v-row>

        </v-layout>

        <div id="footersDetail">
            <!-- <div style="width:100%; height:20px; padding:2px 0; box-sizing:border-box; background:rgba(21, 140, 67, 0.7); margin-bottom:10px; text-align:center;">
                <h5 style="font-size:10px; color:#ffffff;"> Generated payment successfully </h5>
            </div> -->
            <div style="width:100%; background:#142699;">
                <v-layout justify-center>
                    <div style="text-align:center; margin-top:1.3em;">
                        <h5 style="color:#FFFFFF; font-size:10px; margin-left:5px;">Powered By</h5>
                        <img src="../../../assets/ipaymu-white.png" style="margin-right:-20px;" />
                    </div>
                </v-layout>
            </div>
        </div>

        <!-- <div style="background:#F2F7F5; width:100%; height:45px; padding:5px 0; position:absolute; bottom:0; left:0;">
                <v-col sm="12" md="12">

                    <v-row class="justify-content-center">

                        <v-col sm="3" md="3" style="text-align:center;"> A </v-col>
                        <v-col sm="3" md="3" style="text-align:center;"> B </v-col>
                        <v-col sm="3" md="3" style="text-align:center;"> C </v-col>
                        <v-col sm="3" md="3" style="text-align:center;"> D </v-col>

                    </v-row>

                </v-col>
        </div> -->

    </div>
  </template>
  
  <script>
  //import CustomCard from "../../../../components/CustomCard.vue";
  //import downloadexcel from "vue-json-excel";
  import QrcodeVue from 'qrcode.vue'
  
  
  export default {
    data() {
      return {
        tes:"tes",
        value: 'https://example.com',
        deviceIsMobile:false,
        //size: navigator.userAgentData.mobile == true ? (screen.width/2.5) : 200,
        size: 200,
        responses:this.$store.state.member.member.paymentRes,
        
      };
    },
    methods: {
      fetch() {
        
      },
      
    },
    mounted() {
      // this.fetchProv("provinsi");
      // this.fetchTotal("");
      this.$store.commit("main/setLoading", false);
      this.$store.state.main.loading = false;

      //alert(document.getElementById("yourDiv").clientWidth);
      
      this.responses = this.$store.state.member.member.paymentRes;
      this.value = this.responses.payment_detail.payment_code;

      //At the beginning we set this flag as false. If we can detect the device is a mobile device in the next line, then we set it as true.

      // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
      //   this.deviceIsMobile = true;
      // }
      if(navigator != undefined && navigator.userAgent != undefined) {
        let user_agent = navigator.userAgent.toLowerCase();
        
        if(user_agent.indexOf('android') > -1) { // Is Android.
            this.deviceIsMobile = true
        }
        else if(user_agent.indexOf('ios') > -1) { // Is Ios.
            this.deviceIsMobile = true
        }
        else if(user_agent.indexOf('iphone') > -1) { // Is Ios.
            this.deviceIsMobile = true
        }
    }

      this.size = this.deviceIsMobile == true ? (screen.width/2.5) : 200

      //alert("tes" + navigator.userAgentData.mobile);
      // alert(this.$store.state.user.ac_read);
      //alert(this.$store.state.user.ac_read);
    },
    watch: {
      // pagination() {
      //     //alert(this.$store.state.member.member.is_back);
      //     if(this.$store.state.member.member.is_back != 1){
      //         this.loading = true
      //         this.loadingtext = 'Loading... Please wait'
      //         // alert("tes");
      //         //console.log("user_con" , "nils "+this.profiles.id);
      //         //alert("tes");
              
      //         this.fetch(0)
      //     }
  
      //     if(this.$store.state.member.member.is_back == 1){
      //         this.pagination.page = this.$store.state.member.member.paging_page;
      //     }
  
      //     this.$store.state.member.member.is_back = 0;
  
      // },
      // searchSponsor(val) {
      //     val && val !== this.searchForm.sponsor && this.getSponsor(val);
      // },
      // searchMember(val) {
      //     val && val !== this.searchForm.member && this.getMember(val);
      // }
  
    },
    
    computed: {
      dateRangeText() {
        if (typeof this.form.dates != "undefined") {
          if (this.form.dates.length > 1) {
            return this.form.dates.join(" ~ ");
          } else {
            return this.form.dates[0];
          }
        } else {
          return null;
        }
      },
    },
    //components: { CustomCard , downloadexcel },
    components: { QrcodeVue },
  };
  </script>
  
  <style>
  </style>