<template>
     <div>
    
        <v-layout>

            <v-row style="margin-top:20px;">

                <v-col sm="12" md="12" style="border-radius:20px 20px 0 0; box-shadow: 0 0 2px 2px #CCCCCC; height:80vh; width:100%; padding:10px 0; box-sizing:border-box; overflow:auto">
                    <div style="padding:0 40px;">
                        <h5 style="font-size:16px; color:#142699; font-weight:bold;"> Payment Detail #{{ responses.payment_detail.transaction_id }}</h5>
                    </div>

                    <div style="margin-top:10px; background:#F3F3F3; height:67vh; width:100%; padding:20px 10px; box-sizing:border-box; overflow:auto;">
                            <v-layout justify-center>

                                 <div>
                                    <img :src="responses.payment_channel.logo" style="width:120px;" />
                                 </div>

                            </v-layout>

                            <v-layout justify-center>
                                
                                <div style="border-top:1px solid #CCCCCC; width:250px; margin-top:10px;"></div>
                                
                            </v-layout>

                            <v-layout justify-center>
                                
                                <div style="text-align:center;" v-if="responses.payment_channel.payment_method_code != 'cstore'">
                                    <h5 style="font-size:12px; color:#808080; margin-top:20px;"> Please transfer to account below: </h5> <p></p>
                                    <h4 style="font-size:14px; color:#262626; font-weight:normal; margin:-10px 0 0 0;"> {{ responses.payment_channel.payment_channel_sub_name }} </h4>
                                </div>

                                <div style="text-align:center;" v-if="responses.payment_channel.payment_method_code == 'cstore'">
                                    <h5 style="font-size:12px; color:#808080; margin-top:20px;"> Please process payment to store below: </h5> <p></p>
                                    <h4 style="font-size:14px; color:#262626; font-weight:normal; margin:-10px 0 0 0;"> {{ responses.payment_channel.payment_channel_sub_name }} </h4>
                                </div>
                                
                            </v-layout>

                            <v-layout justify-center style="margin-top:20px;">

                                <v-col sm="10" md="10" cols="8"> 

                                <v-row>

                                    <v-col sm="10" md="10" cols="10" style="margin:0; padding:0;">

                                        <v-text-field
                                            class="elevation-0"
                                            v-model="copyvalue"
                                            label="Copy Value"
                                            placeholder="Placeholder"
                                            id="input_copy_value"
                                            readonly
                                            solo>
                                        </v-text-field>

                                    </v-col>

                                    <v-col @click="copy_val()" sm="2" md="2" cols="2" style="background:#DADADA; margin:0; padding:0; height:48px; padding:14px 0; box-sizing:border-box; text-align:center; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);">
                                            <h5 style="color:#666666;"><v-icon>mdi-content-copy</v-icon></h5>
                                    </v-col>

                                </v-row>

                                </v-col>

                            </v-layout>

                            <v-layout justify-center style="margin-top:-10px;">

                                <p></p>
                                
                                <div>
                                    <h5 style="font-size:14px; color:#262626;"> {{ responses.payment_detail.payment_name }} </h5> <p></p>
                                </div>
                                
                            </v-layout>

                            <v-layout justify-center style="margin-top:-10px;">
                                
                                <div style="border-top:1px solid #CCCCCC; width:250px; margin-top:10px;"></div>
                                
                            </v-layout>

                            <v-layout justify-center>
                                
                                <div style="text-align:center;">
                                    <h5 style="font-size:12px; color:#808080; margin-top:10px;"> Amount </h5> <p></p>
                                    <h4 style="font-size:18px; color:#262626;  margin:-10px 0 0 0;">Rp. {{ responses.payment_detail.total_idr }}</h4>

                                    <h5 style="font-size:12px; color:#808080; margin-top:10px;"> Payment Expired </h5> <p></p>
                                    <h4 style="font-size:14px; color:#FF0000;  margin:-10px 0 0 0;">{{ responses.payment_detail.expired_format }}</h4>

                                    <p></p>
                                <div> 
                                
                                     
                                        <!-- <v-btn
                                              :href="responses.payment_channel.documentation"
                                              target="_blank"
                                              icon
                                          >
                                              <v-icon>window</v-icon> {{ responses.payment_channel.documentation }}
                                        </v-btn> -->

                                    </div>
                                </div>
                                
                            </v-layout>

                            <v-layout style="margin-top:10px;">

                               <v-col sm="12" md="12">
                                    <a :href="responses.payment_channel.documentation" target="_blank" style="margin-top:10px; text-decoration:none;">
                                         <v-btn  color="success" style="width:100%; height:40px;">
                                            Cara Pembayaran
                                          </v-btn>
                                    </a>
                               </v-col>

                            </v-layout>

                    </div>

                </v-col>

            </v-row>

        </v-layout>

        <div id="footersDetail">
            <!-- <div style="width:100%; height:20px; padding:2px 0; box-sizing:border-box; background:rgba(21, 140, 67, 0.7); margin-bottom:15px; text-align:center;">
                <h5 style="font-size:10px; color:#ffffff;"> Generated payment successfully </h5>
            </div> -->
            <div style="width:100%; background:#142699;">
                <v-layout justify-center>
                    <div style="text-align:center; margin-top:1.3em;">
                        <h5 style="color:#FFFFFF; font-size:10px; margin-left:5px;">Powered By</h5>
                        <img src="../../../assets/ipaymu-white.png" style="margin-right:-20px;" />
                    </div>
                </v-layout>
            </div>
        </div>

        <div style="background:#F2F7F5; width:100%; height:45px; padding:5px 0; position:absolute; bottom:0; left:0;">
                <v-col sm="12" md="12">

                    <v-row class="justify-content-center">

                        <v-col sm="3" md="3" style="text-align:center;"> A </v-col>
                        <v-col sm="3" md="3" style="text-align:center;"> B </v-col>
                        <v-col sm="3" md="3" style="text-align:center;"> C </v-col>
                        <v-col sm="3" md="3" style="text-align:center;"> D </v-col>

                    </v-row>

                </v-col>
        </div>

    </div>
  </template>
  
  <script>
  //import CustomCard from "../../../../components/CustomCard.vue";
  //import downloadexcel from "vue-json-excel";

  // function myFunction(inputid) {
  //     var jsCopy = document.getElementById(inputid);
      
  //     jsCopy.select();
  //     jsCopy.setSelectionRange(0, 99999)
  //     document.execCommand("copy");
  //   }
  
  
  export default {
    data() {
      return {
        tes:"tes",
        responses:this.$store.state.member.member.paymentRes,
        copyvalue:""
      };
    },
    methods: {
      fetch() {
      },
      copy_val(){
        this.$store.commit("main/setSnackbarSuccess", "Copy Success");
        var jsCopy = document.getElementById("input_copy_value");
        jsCopy.select();
        jsCopy.setSelectionRange(0, 99999)
        document.execCommand("copy");

        //alert("copied");
      }
    },
    mounted() {
      this.$store.commit("main/setLoading", true);
      this.$store.commit("main/setLoading", false);

      this.copyvalue = this.responses.payment_detail.payment_code;
    },
    watch: {
    },
    
    computed: {
      dateRangeText() {
        if (typeof this.form.dates != "undefined") {
          if (this.form.dates.length > 1) {
            return this.form.dates.join(" ~ ");
          } else {
            return this.form.dates[0];
          }
        } else {
          return null;
        }
      },
    },
    //components: { CustomCard , downloadexcel },
    //components: { CustomCard },
  };
  </script>
  
  <style>
  </style>